<template>
  <div class="gabyk">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~vuetify/dist/vuetify.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import "assets/sassb5/plugins";
@import "assets/sassb5/style";

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  //overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.menu-color-1 {
  fill: #fff !important;
}

.menu-color-2 {
  fill: #d23fc3 !important;
}

.custom-content-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 190px) !important;
}
.custom-content-height::-webkit-scrollbar {
  width: 12px !important;
}
.custom-content-height::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}

.v-data-table__wrapper::-webkit-scrollbar {
  height: 12px !important;
  width: 12px !important;
}
.v-data-table__wrapper::-webkit-scrollbar-thumb {
  min-height: 100px !important;

  border-radius: 12px !important;
}

//.v-data-table__wrapper > table::-webkit-scrollbar {
//  width: 12px !important;
//}
//.v-data-table__wrapper > table::-webkit-scrollbar-thumb {
//  border-radius: 12px !important;
//}
th.text-start {
  // for datatable headers
  z-index: 4 !important;
}
//.custom-datatable-height {
//  overflow-x: scroll;
//  height: calc(100vh - 290px) !important;
//}

.v-application {
  height: calc(100vh - 171px) !important;
}

.v-application .v-application--wrap {
  height: calc(100vh - 171px) !important;
  min-height: 10vh !important;
}

@media (max-width: 1199.98px) {
  .v-application {
    height: calc(100vh - 141px) !important;
  }

  .v-application .v-application--wrap {
    height: calc(100vh - 141px) !important;
    min-height: 10vh !important;
  }

  .custom-content-height {
    height: calc(100vh - 160px) !important;
  }
}

@media (max-width: 991.98px) {
  .v-application {
    height: calc(100vh - 127px) !important;
  }

  .v-application .v-application--wrap {
    height: calc(100vh - 127px) !important;
    min-height: 10vh !important;
  }

  .custom-content-height {
    height: calc(100vh - 148px) !important;
  }

  .menu-color-1 {
    fill: #abbcdc !important;
  }

  .menu-color-2 {
    fill: #c44bb8 !important;
  }
}

textarea::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}
textarea::-webkit-scrollbar-thumb {
  background-color: #ebedf3;
}
textarea:hover::-webkit-scrollbar-thumb {
  background-color: #e5e7ef;
}

.gabyk {
  padding-top: env(safe-area-inset-top);
}

.custom-dialog-height.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100vh !important;
}

.badge-light-success {
  color: #14b519;
  background-color: #e1f7e3 !important;
}

.poppins {
  font-family: Poppins, Helvetica, sans-serif !important;
}
.poppins-ls {
  font-family: Poppins, Helvetica, sans-serif !important;
  letter-spacing: 1.2px !important;
  white-space: nowrap !important;
}
.ls1 {
  letter-spacing: 1px;
}
.custom-btn {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: 500;
  letter-spacing: 1px;
}

.btn.btn-success,
.btn.btn-success i {
  color: #fff;
  background-color: #50cd89;
}

.btn.btn-success:hover,
.btn.btn-success:active,
.btn.btn-success:hover i,
.btn.btn-success:active i {
  color: #fff;
  background-color: #47be7d !important;
}

.btn.btn-light-success,
.btn.btn-light-success i {
  color: #50cd89;
  background-color: #e8fff3;
}
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-success.dropdown-toggle,
.show .btn.btn-light-success.btn-dropdown,
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text),
.btn.btn-light-success:hover i,
.btn.btn-light-success:active i {
  color: #fff;
  background-color: #50cd89 !important;
}

.jse-menu.svelte-497ud4.svelte-497ud4 {
  background: #8950fc !important;
  border-bottom: 1px solid #8950fc !important;
}

.jse-menu.svelte-497ud4
  .jse-button.jse-group-button.jse-selected.svelte-497ud4 {
  background: white !important;
  color: #8950fc !important;
}

//------------------Datatable Top buttons-----------------------
.btn--tools {
  color: #7e04b7 !important;
  background-color: white !important;
  border: 1px solid #7e04b7 !important;
  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 6px 12px;
  outline: 0 !important;
  width: 100px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn--tools:hover {
  color: white !important;
  background-color: rgba(126, 4, 183, 0.8) !important;
}

.btn--print-upload {
  background-color: #7e04b7 !important;
  color: white !important;

  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  outline: 0 !important;
  min-width: 110px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  i {
    color: white !important;
  }
}
.btn--print-upload:hover {
  color: white !important;
  background-color: rgba(126, 4, 183, 0.8) !important;
}

.btn--export-filter {
  background-color: #eee5ff !important;
  color: #8950fcff !important;
  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  outline: 0 !important;
  min-width: 110px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  i {
    color: #8950fcff !important;
  }
}
.btn--export-filter:hover {
  background-color: rgba(126, 4, 183, 0.8) !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.btn--export-filter.btn.btn-info:hover:not(.btn-active) {
  background-color: rgba(126, 4, 183, 0.8) !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.show > .btn.btn-info.dropdown-toggle {
  background-color: rgba(126, 4, 183, 0.8) !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.btn--export-filter.btn.btn-info:focus:not(.btn-active) {
  background-color: rgba(126, 4, 183, 0.8) !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #101221;
  background-color: #ebedf3;
}

.iq-color {
  color: #7e04b7 !important;
}

.iq-bg {
  background-color: #7e04b7 !important;
  //color: white !important;
}

.iq-border-color {
  border: 1px solid #7e04b7 !important;
}

.iq-btn {
  background-color: #7e04b7 !important;
  color: white !important;
}

.iq-btn:hover {
  background-color: rgba(126, 4, 183, 0.8) !important;
}

.iq-btn:disabled {
  background-color: rgba(126, 4, 183, 0.9) !important;
}

.iq-light-btn {
  background-color: #eee5ff !important;
  color: #8950fcff !important;
  outline: 0 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  i {
    color: #8950fcff !important;
  }
}
.iq-light-btn:hover {
  background-color: rgba(126, 4, 183, 0.8) !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.daterangepicker .drp-calendar td.active {
  background-color: #8950fcff !important;
}

.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
  //color: #8950fcff !important;
  color: rgba(126, 4, 183, 0.8) !important;
}

.daterangepicker .drp-calendar td:hover {
  background-color: #ebf4f8 !important;
  color: rgba(126, 4, 183, 0.8) !important;
}

//.daterangepicker td.active,
//.daterangepicker td.active:hover {
//  color: #fff !important;
//}

.daterangepicker .drp-calendar th.next.available:hover span,
.daterangepicker .drp-calendar th.prev.available:hover span {
  border-color: #8950fcff !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #8950fcff !important;
}

.v-text-field--outlined fieldset {
  border-color: #dcdfe8 !important;
}
.v-label.theme--light.primary--text {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-icon.theme--light.primary--text {
  color: rgba(0, 0, 0, 0.54) !important;
}

.v-input--checkbox label,
.v-input--switch label {
  margin-bottom: 0 !important;
}

.daterangepicker .btn-primary {
  color: #ffffff !important;
  border-color: #3699ff !important;
  background-color: #3699ff !important;
}
.daterangepicker .btn-light {
  border-color: #f3f6f9 !important;
  background-color: #f3f6f9 !important;
}

.daterangepicker .calendars {
  flex-wrap: nowrap !important;
}

.custom-autocomplete {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #3f4254 !important;
}
.v-file-input__text.v-file-input__text--placeholder {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #b5b5c3 !important;
}

//-----------------Datatable Top buttons end--------------------

.menu-item-active {
  -webkit-transition: background-color 0.3s;
  text-decoration-color: #7e04b7 !important;
  transition: background-color 0.3s;
  color: #7e04b7 !important;
}
.menu-item-open {
  color: #7e04b7 !important;
}

.headerZindex {
  z-index: 0 !important;
}

.text-start {
  z-index: 0 !important;
}

th {
  div,
  i {
    display: inline-block;
  }
}

.first-text {
  color: #3f4254 !important;
  font-size: 1.15rem !important;
  font-weight: 600 !important;
  font-family: Poppins, Helvetica, sans-serif;
  align-content: flex-start !important;
  transition: color 0.2s ease, background-color 0.2s ease !important;
}

.first-text:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: rgba(126, 4, 183, 0.8) !important;
  cursor: pointer;
}

.second-text {
  color: #7e8299 !important;
  font-size: 1.075rem !important;
  font-weight: 600 !important;
  font-family: Poppins, Helvetica, sans-serif;
  white-space: nowrap !important;
  vertical-align: middle;
  align-content: flex-start !important;
  transition: color 0.2s ease, background-color 0.2s ease !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > :not(caption)
  > *
  > * {
  padding: 0.55rem 0.55rem;
  vertical-align: middle;
  font-family: Poppins, Helvetica, sans-serif;
}

.theme--light.v-data-table.dataTable > .v-data-table__wrapper > table td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  vertical-align: middle;
  font-family: Poppins, Helvetica, sans-serif;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.45em;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("../public/img/checked.svg");
  background-position: center;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background-color: #faf7ff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #faf7ff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
  > th:first-of-type
  > .form-check.form-check-solid
  .form-check-input:not(.form-check-input:checked[type="checkbox"]):hover {
  border: 0;
  background-color: #ccc7ff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  )
  > td
  > .form-check.form-check-solid
  .form-check-input {
  border: 0;
  background-color: #ccc7ff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  )
  > td
  > .form-check.form-check-solid
  .form-check-input:checked[type="checkbox"] {
  border: 0;
  background-color: #a929e6 !important;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: #a929e6 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(181, 181, 195, 0.22) !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child {
  border-bottom: thin solid rgba(181, 181, 195, 0.22) !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.875rem;
  font-family: Poppins, Helvetica, sans-serif;
}

//.v-list-item .v-btn:hover {
//  transition: color 0.2s ease, background-color 0.2s ease !important;
//  background-color: #f1faff !important;
//  color: #009ef7 !important;
//}

//filter
.myfilters .v-select__selections {
  height: 26px;
  overflow-y: scroll;
}
.myfilters .v-select__selections::-webkit-scrollbar {
  display: none;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #fff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child {
  border-bottom: none !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.multiselect__tags {
  border-color: #dcdfe8 !important;
  padding-left: 12px !important;
}

.multi-select-filter-sidebar > div.multiselect__tags {
  overflow-y: scroll !important;
  max-height: 100px !important;
}

.multiselect__content-wrapper {
  border-radius: 0 !important;
  border-color: #dcdfe8 !important;
}

.multiselect__content {
  padding-left: 0 !important;
}

.multiselect__tag {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.multiselect__tag-icon:after {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 20px !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: #e0e0e0 !important;
}

.multiselect__placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: "Poppins", "Helvetica", sans-serif !important;
}

.multiselect__option,
.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.multiselect__clear {
  position: absolute;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
  transform: rotate(45deg);
}
.multiselect__clear:after {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
  transform: rotate(-45deg);
}
//.multiselect__option:hover,
//.multiselect__option:focus,
//.multiselect__option--highlight:hover,
//.multiselect__option--highlight:focus,
//.multiselect__option--highlight::after:hover,
//.multiselect__option--highlight::after:focus {
//  background: red !important;
//  //background-color: red !important;
//  //background-color: #e0e0e0 !important;
//}

.v-select__selections {
  height: 26px;
  overflow-y: scroll;
  align-content: center;
  justify-items: center;
}
.v-select__selections::-webkit-scrollbar {
  display: none;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  font-family: "Poppins", "Helvetica", sans-serif !important;
}

.v-chip .v-chip__content {
  font-family: "Poppins", "Helvetica", sans-serif !important;
}

.v-stepper__wrapper {
  padding-top: 0 !important;
}

.jsoneditor {
  border: thin solid #dcdfe8 !important;
  border-radius: 0.25rem !important;
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

export default {
  name: "App",
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false,
    snackBtnText: "",
    snackWithBtnText: "",
    snackWithButtons: false,
  }),
  created() {
    // document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if (navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //     if (this.refreshing) return;
    //     this.refreshing = true;
    //     window.location.reload();
    //   });
    // }
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.commit(OVERRIDE_LAYOUT_CONFIG);
  },
  // computed: {
  //   page_loading: function () {
  //     return !!this.$store.getters.getPageLoading;
  //   },
  //   isUpdateExists() {
  //     return this.updateExists;
  //   },
  // },
  // methods: {
  // showRefreshUI(e) {
  //   this.registration = e.detail;
  //   this.updateExists = true;
  // },
  // showRefreshUI(e) {
  // Display a snackbar inviting the user to refresh/reload the app due
  // to an app update being available.
  // The new service worker is installed, but not yet active.
  // Store the ServiceWorkerRegistration instance for later use.
  //   this.registration = e.detail;
  //   this.updateExists = true;
  //   this.snackBtnText = "Refresh";
  //   this.snackWithBtnText = "New version available!";
  //   this.snackWithButtons = true;
  // },
  // refreshApp() {
  //   this.updateExists = false;
  //   if (!this.registration || !this.registration.waiting) {
  //     return;
  //   }
  //   this.registration.waiting.postMessage("skipWaiting");
  // },
  // },
};
</script>
